<template>
  <div>
    <b-card style="min-height: 250px">
      <h4 class="text-danger">Erişiminiz Kapalı!</h4>
      <hr style="margin: 10px 0px 20px 0px !important" />
      <p>
        <strong>"{{ moduleName }}"</strong> modülüne erişim yetkiniz
        bulunmamaktadır.
      </p></b-card
    >
  </div>
</template>

<script>
import store from "@/store/index";
import {
  BCard,
  BCardText,
  BTable,
  BSpinner,
  BPagination,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormFile,
  BFormTextarea,
  BTabs,
  BTab,
  BImg,
  BAlert,
  BBadge,
} from "bootstrap-vue";


export default {
  components: {
    BCard,
    BCardText,
    BTable,
    BSpinner,
    BPagination,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormGroup,
    BButton,
    BFormFile,
    BFormTextarea,
    BTabs,
    BTab,
    BImg,
    BAlert,
    BBadge,
  },
  data() {
    return {
      moduleName: "",
    };
  },

  computed: {},

  watch: {},

  methods: {},

  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.moduleName = urlParams.get("moduleName") || "";
  },
};
</script>

<style lang="scss">
</style>
